import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import validationSchemas from "../../utils/validations/validationSchemas";
import CustomBtn from "../../components/CustomBtn";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AiOutlineCheckCircle } from "react-icons/ai";
import MessageModal from "../../shared/loader/MessageModal";
import { setMessage } from "../../features/appSlice";
import CustomInput from "../../components/CustomInput";
import DropdownWithSearch from "../../components/DropdownWithSearch";
import { addNewCustomerRequest } from "../../features/customerSlice";
import { customersAPI } from "../../api/customerAPI";
import { partnersAPI } from "../../api/partnersAPI";
import ToastNotification from "../../shared/ToastNotification";

const AddNewCustomer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const message = useSelector((state) => state.app.message);
  const loading = useSelector((state) => state.app.isLoading);
  const submitError = useSelector((state) => state.partners.hasError);
  const [usernameAvailablity, setUsernameAvailablity] = useState(false);
  const [touch, setTouch] = useState(false);
  const [partner, setPartner] = useState(false);
  const [partnersList, setPartnersList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const clearNotificationMessage = () => {
    dispatch(setMessage(""));
    navigate("/customers-menu");
  };

  const checkUsername = async (params) => {
    if (!params) return;
    let check = await customersAPI.checkUsername(params);
    setUsernameAvailablity(check.available);
  };

  const onCreate = (values) => {
    if (!usernameAvailablity)
      return setErrorMessage("check username available or not ");
    if (!partner) return setErrorMessage("Please select Channel Partner");

    let partnerId = partner.match(/\(([^)]+)\)/)[1];
    let valuesWithPartnerId = { ...values, channelPartnerId: partnerId };
    dispatch(addNewCustomerRequest(valuesWithPartnerId));
  };

  useEffect(() => {
    const getAllPartners = async () => {
      let data = await partnersAPI.getAllPartners();

      let all = [];
      for (const key in data.partners) {
        let showName = `${data.partners[key].fullName} (${data.partners[key].username})`;
        all.push(showName);
      }
      setPartnersList(all);
    };

    getAllPartners();
  }, []);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        clearNotificationMessage();
      }, 4000);
    }
    // eslint-disable-next-line
  }, [message]);

  return (
    <>
      {!message ? (
        <div
          style={{ minHeight: "80vh" }}
          className="mt-4   bg-secoundry shadow-dhruv rounded dark:bg-darkmodeSecoundry "
        >
          <div className=" pb-8">
            <h2 className=" font-semibold px-4 text-lg ml-4 pt-4 text-blue-500 dark:text-darkmodeFots ">
              Add Customer
            </h2>
            <ToastNotification
              message={errorMessage}
              open={errorMessage}
              setOpen={() => setErrorMessage("")}
            />
            <Formik
              enableReinitialize
              initialValues={{
                fullName: "",
                username: "",
                email: "",
                password: "",
                isDocumentVerified: false,
              }}
              validationSchema={validationSchemas.registerCustomerValidation}
              onSubmit={onCreate}
            >
              {({ errors, touched, values }) => (
                <Form autoComplete="off">
                  <div
                    className={`flex justify-start md:flex-row flex-col  items-center pt-4 `}
                  >
                    <div className=" md:w-2/3 w-full  md:px-4">
                      <div className={`px-4 w-full`}>
                        <CustomInput
                          errors={errors.fullName}
                          touched={touched.fullName}
                          title="Enter Full Name"
                          name="fullName"
                          placeholder="Enter Name"
                        />
                      </div>
                    </div>
                    <div className="md:w-2/3 w-full px-2 md:px-0 ">
                      <div
                        className={`px-2  pr-8 w-full flex justify-between items-center`}
                      >
                        <CustomInput
                          errors={errors.username}
                          touched={touched.username}
                          title="Unique Username"
                          name="username"
                          placeholder="Enter username"
                        />
                        <button
                          type="button"
                          onClick={() => checkUsername(values.username)}
                          className={` hover:bg-opacity-80 w-full  md:w-auto text-sm disabled:bg-gray-400 disabled:text-gray-100 py-2 px-4 rounded text-white mt-1 lg:ml-5 outline-none lg:hover:scale-105 transform ${
                            usernameAvailablity
                              ? "bg-green-500"
                              : "bg-indigo-500"
                          }`}
                        >
                          {usernameAvailablity ? (
                            <AiOutlineCheckCircle className="text-xl" />
                          ) : (
                            "Check"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`flex justify-start md:flex-row flex-col  items-center pt-4 `}
                  >
                    <div className=" md:w-2/3 w-full  md:px-6">
                      <div className={`px-2  w-full`}>
                        <CustomInput
                          errors={errors.email}
                          touched={touched.email}
                          title="Enter Email"
                          name="email"
                          placeholder="Enter Email"
                        />
                      </div>
                    </div>
                    <div className="md:w-2/3 w-full px-2 md:px-0 ">
                      <div className={`px-2 pr-8 w-full`}>
                        <CustomInput
                          errors={errors.password}
                          touched={touched.password}
                          title="Enter Password"
                          name="password"
                          placeholder="Enter Password"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className={`flex justify-start md:flex-row flex-col  items-center pt-4 `}
                  >
                    <div className="md:w-1/3 w-full mb-4 ml-8 md:px-0 ">
                      <DropdownWithSearch
                        text="sm" // text-xl text-xs
                        width="w-full" // w-48 , w-1/2 w-full
                        name="select" // name
                        array={partnersList} // array to iterate
                        py="[6px]" // padding top & bottom
                        touch={touch} // touch
                        setTouch={setTouch} // set
                        label={"Select Channel Partner"}
                        placeholderText="Select Channel Partner"
                        defaultValue="Select Channel Partner"
                        value={partner}
                        setValue={setPartner}
                      />
                    </div>
                    <div>
                      <div className=" ml-4 text-gray-600 font-medium text-sm mb-1 dark:text-gray-300">
                        Docuement Verified
                      </div>
                      <Field
                        name="isDocumentVerified"
                        type="checkbox"
                        className={`${
                          errors.isDocumentVerified &&
                          touched.isDocumentVerified
                            ? "border-red-500"
                            : "border-gray-300"
                        }   outline-none  dark:text-gray-300 border rounded py-2 font-normal transition-all duration-300 focus:border-indigo-500 dark:border-gray-500 px-3 w-full text-sm text-gray-700 caret-indigo-600 dark:bg-darkmodeSecoundry `}
                      />
                    </div>
                  </div>

                  <div className="px-3 py-4">
                    <CustomBtn
                      type="submit"
                      text={"Add Customer"}
                      loading={loading}
                    />
                  </div>
                  {submitError && (
                    <div className="p-3 pl-8 text-red-500">{submitError}</div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <MessageModal
          open={message}
          setOpen={clearNotificationMessage}
          message={message}
        />
      )}
    </>
  );
};

export default AddNewCustomer;
