import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import validationSchemas from "../../utils/validations/validationSchemas";
import CustomBtn from "../../components/CustomBtn";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MessageModal from "../../shared/loader/MessageModal";
import { setMessage } from "../../features/appSlice";
import NumberInput from "../../components/NumberInput";
import {
  singleCustomerRequest,
  addProfitToCustomerRequest,
} from "../../features/customerSlice";
import { getCurrentTimeWithISTOffset } from "../../utils/helpers/helper";

const AddProfitToCustomer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const indiaTime = getCurrentTimeWithISTOffset();
  const message = useSelector((state) => state.app.message);
  const loading = useSelector((state) => state.app.isLoading);
  const submitError = useSelector((state) => state.customers.hasError);
  const singleCustomer = useSelector((state) => state.customers.singleCustomer);

  const clearNotificationMessage = () => {
    dispatch(setMessage(""));
    navigate("/customers-menu");
  };

  const onCreate = (values) => {
    let dataWithId = { ...values, id };
    if (values.profit !== 0) {
      dispatch(addProfitToCustomerRequest(dataWithId));
    }
  };

  useEffect(() => {
    dispatch(singleCustomerRequest(id));
  }, [dispatch, id]);

  React.useEffect(() => {
    if (message) {
      setTimeout(() => {
        clearNotificationMessage();
      }, 4000);
    }
    // eslint-disable-next-line
  }, [message]);

  return (
    <>
      {!message ? (
        <div
          style={{ minHeight: "80vh" }}
          className="mt-4 rounded bg-secoundry shadow-dhruv dark:bg-darkmodeSecoundry "
        >
          <div className="pb-8 ">
            <h2 className="px-4 pt-4 ml-4 text-lg font-semibold text-blue-500 dark:text-darkmodeFots">
              Add Profit to Customer
            </h2>
            <Formik
              enableReinitialize
              initialValues={{
                profit: 0,
                date: new Date().toISOString().split("T")[0], // Set initial date to current date
                time: indiaTime, // Set initial time to current time
              }}
              validationSchema={validationSchemas.addProfitIsNumberValidation}
              onSubmit={onCreate}
            >
              {({ errors, touched, values }) => (
                <Form autoComplete="off">
                  <div
                    className={`flex justify-start md:flex-row flex-col  items-center pt-4 `}
                  >
                    <div className="w-full md:w-1/3 md:px-4">
                      <div className={`px-4 w-full`}>
                        <NumberInput
                          errors={errors.profit}
                          touched={touched.profit}
                          title="Enter Amount"
                          name="profit"
                          placeholder="Enter Profit > 0"
                        />
                      </div>
                    </div>
                    <div className="w-full md:w-1/3 md:px-4">
                      {/* Date Input */}
                      <div className={`px-4 w-full`}>
                        <label
                          htmlFor="date"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Select Date
                        </label>
                        <Field
                          type="date"
                          id="date"
                          name="date"
                          value={values.date}
                          className="w-full p-2 mt-1 border rounded-md"
                        />
                      </div>
                    </div>
                    <div className="w-full md:w-1/3 md:px-4">
                      {/* Time Input */}
                      <div className={`px-4 w-full`}>
                        <label
                          htmlFor="time"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Select Time
                        </label>
                        <Field
                          type="time"
                          id="time"
                          name="time"
                          value={values.time}
                          className="w-full p-2 mt-1 border rounded-md"
                        />
                      </div>
                    </div>
                  </div>
                  {singleCustomer && (
                    <h6 className="pl-8 text-xs leading-relaxed text-red-600 lg:text-base">
                      Are You Sure you want to add Profit in{"  "}
                      <span className="text-blue-500 capitalize">
                        {singleCustomer.fullName}'s{"  "}
                      </span>
                      Account ?
                      <br />
                      This can not be undone
                      <br />
                      Think twice before continue
                    </h6>
                  )}
                  <div className="px-3 py-4">
                    <CustomBtn
                      disabled={values.profit === 0}
                      type="submit"
                      text={"Add Profit to Customer"}
                      loading={loading}
                    />
                  </div>
                  {submitError && (
                    <div className="p-3 pl-8 text-red-500">{submitError}</div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <MessageModal
          open={message}
          setOpen={clearNotificationMessage}
          message={message}
        />
      )}
    </>
  );
};

export default AddProfitToCustomer;
