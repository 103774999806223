import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { singleCustomerTransaction } from "../../features/customerSlice";
import BankDetailModal from "../../components/modals/BankDetailModal";

const ShowBankDetail = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const singleTransaction = useSelector(
    (state) => state.customers.singleCustomerTransaction
  );

  useEffect(() => {
    dispatch(singleCustomerTransaction(id));
  }, [dispatch, id]);

  return (
    <BankDetailModal
      cancelLink="/customers-menu/pending-transactions"
      transaction={singleTransaction}
    />
  );
};

export default ShowBankDetail;
