import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  BsSortNumericDown,
  BsSortNumericDownAlt,
  BsSortAlphaDown,
  BsSortAlphaDownAlt,
  BsSortDown,
  BsSortDownAlt,
} from "react-icons/bs";
import { BiSortAlt2 } from "react-icons/bi";
import { stringSorting, numberSort, dateSortWithTime } from "../utils/sorting";
import { formateDate, smallString, formatTime } from "../utils/helpers/helper";
import ActionIcons from "./ActionIcons";

let filter = [];
let colors = [
  "bg-yellow-600",
  "bg-red-600",
  "bg-indigo-600",
  "bg-gray-600",
  "bg-green-600",
  "bg-orange-600",
  "bg-amber-600",
  "bg-lime-600",
  "bg-pink-600",
  "bg-rose-600",
  "bg-fuchsia-600",
  "bg-purple-600",
  "bg-sky-600",
  "bg-violet-600",
  "bg-cyan-600",
  "bg-teal-600",
  "bg-emerald-600",
];
const DynamicTable = (props) => {
  const [array, setArray] = useState([]);
  const [order, setOrder] = useState("asc");
  const [sort, setSort] = useState(false);
  const [prev, setPrev] = useState(true);

  const onClickSort = (col, type) => {
    if (type === "string") {
      stringSorting(props.mappingDataArray, col, order, setOrder, setArray);
      setSort(true);
      filter = [];
      filter.push(col);
    }
    if (type === "number") {
      numberSort(props.mappingDataArray, col, prev, setPrev, setArray);
      setSort(true);
      filter = [];
      filter.push(col);
    }
    if (type === "date") {
      dateSortWithTime(props.mappingDataArray, col, order, setOrder, setArray);
      setSort(true);
      filter = [];
      filter.push(col);
    }
  };
  useEffect(() => {}, [sort]);
  useEffect(() => {}, [props.search]);

  return (
    <table className="block w-full pb-4 overflow-x-auto table-fixed lg:overflow-x-hidden whitespace-nowrap lg:table">
      <thead className="w-full p-8 ">
        <tr className="text-left bg-table">
          {props.tableHeaderArray !== undefined &&
            props.tableHeaderArray.length > 0 &&
            props.tableHeaderArray.map((cell, index) => {
              return (
                <th
                  key={index}
                  onClick={() => onClickSort(cell.code, cell.type)}
                  className={`${props.py ? props.py : "py-[6px]"} ${
                    cell.width ? cell.width : ""
                  } ${
                    cell.textCenter ? "text-center" : "pl-6"
                  }  group hover:bg-gray-200 dark:hover:bg-tableclmn dark:text-darkmodeFots text-gray-600 dark:bg-tableclmn select-none cursor-pointer`}
                >
                  <span
                    className={`mr-4 tracking-wider font-medium  ${
                      props.textSize ? props.textSize : "text-xs"
                    }`}
                  >
                    {cell.name}
                  </span>
                  {cell.type === "string" && !filter.includes(cell.code) && (
                    <BiSortAlt2
                      className={`${
                        props.textSize ? props.textSize : "text-base"
                      } inline text-gray-400 group-hover:text-blue-600`}
                    />
                  )}
                  {cell.type === "string" &&
                    sort &&
                    filter.includes(cell.code) &&
                    order === "asc" && (
                      <BsSortAlphaDownAlt
                        className={`${
                          props.textSize ? props.textSize : "text-base"
                        } inline text-blue-600`}
                      />
                    )}
                  {cell.type === "string" &&
                    sort &&
                    filter.includes(cell.code) &&
                    order === "dsc" && (
                      <BsSortAlphaDown
                        className={`${
                          props.textSize ? props.textSize : "text-base"
                        }   inline text-blue-600 `}
                      />
                    )}
                  {cell.type === "number" && !filter.includes(cell.code) && (
                    <BiSortAlt2
                      className={`${
                        props.textSize ? props.textSize : "text-base"
                      }   inline text-gray-400 group-hover:text-blue-600`}
                    />
                  )}
                  {cell.type === "number" &&
                    sort &&
                    filter.includes(cell.code) &&
                    !prev && (
                      <BsSortNumericDownAlt
                        className={`${
                          props.textSize ? props.textSize : "text-base"
                        } inline text-blue-600`}
                      />
                    )}
                  {cell.type === "number" &&
                    sort &&
                    filter.includes(cell.code) &&
                    prev && (
                      <BsSortNumericDown
                        className={`${
                          props.textSize ? props.textSize : "text-base"
                        } inline text-blue-600`}
                      />
                    )}

                  {cell.type === "date" && !filter.includes(cell.code) && (
                    <BiSortAlt2
                      className={`${
                        props.textSize ? props.textSize : "text-base"
                      } inline text-gray-400 group-hover:text-blue-600`}
                    />
                  )}
                  {cell.type === "date" &&
                    sort &&
                    filter.includes(cell.code) &&
                    order === "asc" && (
                      <BsSortDown
                        className={`${
                          props.textSize ? props.textSize : "text-base"
                        } inline text-blue-600`}
                      />
                    )}
                  {cell.type === "date" &&
                    sort &&
                    filter.includes(cell.code) &&
                    order === "dsc" && (
                      <BsSortDownAlt
                        className={`${
                          props.textSize ? props.textSize : "text-base"
                        } inline text-blue-600`}
                      />
                    )}
                </th>
              );
            })}

          {props.tableActionHeader && (
            <th
              className={` ${
                props.textSize ? props.textSize : "text-xs"
              }  py-3  text-gray-600 dark:text-darkmodeFots text-center tracking-wider font-medium select-none cursor-pointer `}
            >
              Action
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {!sort && !props.search
          ? props.mappingDataArray &&
            props.mappingDataArray.length > 0 &&
            props.mappingDataArray.map((all, index) => {
              return (
                <tr
                  key={index}
                  className="pl-6 even:bg-gray-100 dark:bg-darkmodeSecoundry"
                >
                  {props.tableHeaderArray.map((tableData, index) => {
                    if (tableData.formatFunction === "formatDate") {
                      return (
                        <td
                          key={index}
                          title={formatTime(all[tableData.code])}
                          className="py-2 pl-6 text-xs font-normal text-gray-700 dark:text-darkmodeFots"
                        >
                          {formateDate(all[tableData.code])}
                        </td>
                      );
                    }

                    if (tableData.formatFunction === "formatTime") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal text-gray-700 dark:text-darkmodeFots"
                        >
                          {formatTime(all[tableData.code])}
                        </td>
                      );
                    }
                    if (tableData.formatFunction === "smallString") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-medium text-slate-600 text dark:text-darkmodeFots"
                        >
                          {smallString(
                            all[tableData.code],
                            tableData.stringClip
                          )}
                        </td>
                      );
                    }
                    if (tableData.link) {
                      return (
                        <Link
                          key={index}
                          to={`${tableData.href}/${all[tableData.code]}`}
                        >
                          <td className="py-2 pl-6 text-xs font-medium text-blue-600 text dark:text-darkmodeFots">
                            {all[tableData.code]}
                          </td>
                        </Link>
                      );
                    }

                    if (
                      all[tableData.code] === "active" ||
                      all[tableData.code] === "settlement" ||
                      all[tableData.code] === "profit"
                    ) {
                      return (
                        <td
                          title={all["phrase"] || ""}
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-green-500 rounded-md">
                            {all[tableData.code]}
                          </span>
                        </td>
                      );
                    }

                    if (tableData.type === "number") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span
                            className={`px-2 py-1 ${
                              colors[Math.floor(Math.random() * colors.length)]
                            } text-white capitalize rounded-md`}
                          >
                            {all[tableData.code]} $
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === "hidden") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span
                            className={`px-2 py-1 ${
                              all[tableData.code] === "hidden"
                                ? "bg-red-500"
                                : "bg-green-500"
                            } text-white capitalize rounded-md`}
                          >
                            {all[tableData.code]}
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === "Y") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-green-500 rounded-md">
                            Active
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === false) {
                      return (
                        <td
                          key={index}
                          title={all["phrase"] || ""}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-xl text-white capitalize rounded-md">
                            ✅
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === true) {
                      return (
                        <td
                          key={index}
                          title={all["phrase"] || ""}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-red-500 rounded-md">
                            X
                          </span>
                        </td>
                      );
                    }
                    if (tableData.pillsShow) {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-green-500 rounded-md">
                            {all[tableData.code]}
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === "Add") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-green-500 rounded-md">
                            Add
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === "deposit") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-purple-500 rounded-md">
                            {all[tableData.code]}
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === "confirmed") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-green-500 rounded-md">
                            {all[tableData.code]}
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === "pending") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-gray-500 rounded-md">
                            {all[tableData.code]}
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === "disabled") {
                      return (
                        <td
                          title={all["phrase"] || ""}
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-red-500 rounded-md">
                            {all[tableData.code]}
                          </span>
                        </td>
                      );
                    }

                    if (
                      all[tableData.code] === "N" ||
                      all[tableData.code] === "rejected"
                    ) {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-red-500 rounded-md">
                            Rejected
                          </span>
                        </td>
                      );
                    }

                    if (
                      all[tableData.code] === "Withdraw" ||
                      all[tableData.code] === "withdraw"
                    ) {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-red-500 rounded-md">
                            Withdraw
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === "inactive") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-red-500 rounded-md">
                            {all[tableData.code]}
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === "Rejected") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-red-500 rounded-md">
                            {all[tableData.code]}
                          </span>
                        </td>
                      );
                    }
                    return (
                      <td
                        key={index}
                        className={` font-normal ${
                          tableData.textCenter ? "text-center" : "pl-6"
                        } text-gray-700  text-xs py-2 dark:text-darkmodeFots`}
                      >
                        {all[tableData.code]}
                      </td>
                    );
                  })}
                  {props.tableActionHeader && (
                    <ActionIcons
                      hideDeleteIcon={props.hideDeleteIcon}
                      slug={all["slug"]}
                      time={all["updatedAt"]}
                      actionTableAllOptions={props.actionTableAllOptions}
                      hideUpdateIcon={props.hideUpdateIcon}
                      updateLinkString={props.updateLinkString}
                      updateLinkTerm={
                        props.updateLinkTerm === "id" ? all._id : all.slug
                      }
                      approveLinkString={props.approveLinkString}
                      approveLinkTerm={
                        props.approveLinkTerm === "id" ? all._id : all.slug
                      }
                      rejectLinkString={props.rejectLinkString}
                      rejectLinkTerm={
                        props.rejectLinkTerm === "id" ? all._id : all.slug
                      }
                      deleteLinkString={props.deleteLinkString}
                      deleteLinkTerm={
                        props.deleteLinkTerm === "id" ? all._id : all.slug
                      }
                      rejectTransaction={props.rejectTransaction}
                      showBankDetail={props.showBankDetail}
                      bankString={props.bankString}
                      bankDetailId={props.bankDetailId === "id" && all._id}
                      approveTransaction={props.approveTransaction}
                      addProfitTransaction={props.addProfitTransaction}
                      addProfitLinkString={props.addProfitLinkString}
                      addProfitLinkTerm={
                        props.addProfitLinkTerm === "id" ? all._id : all.slug
                      }
                      updateParameters={props.updateParameters}
                      updateParametersLinkString={
                        props.updateParametersLinkString
                      }
                      updateParametersLinkTerm={
                        props.updateParametersLinkTerm === "id"
                          ? all._id
                          : all.slug
                      }
                      depositParameters={props.depositParameters}
                      depositParametersLinkString={
                        props.depositParametersLinkString
                      }
                      depositParametersLinkTerm={
                        props.depositParametersLinkTerm === "id"
                          ? all._id
                          : all.slug
                      }
                    />
                  )}
                </tr>
              );
            })
          : props.search
          ? props.searchArray &&
            props.searchArray.length > 0 &&
            props.searchArray.map((all, index) => {
              return (
                <tr
                  key={index}
                  className="pl-6 even:bg-gray-100 dark:bg-darkmodeSecoundry"
                >
                  {props.tableHeaderArray.map((tableData, index) => {
                    if (tableData.formatFunction === "formatDate") {
                      return (
                        <td
                          key={index}
                          title={formatTime(all[tableData.code])}
                          className="py-2 pl-6 text-xs font-normal text-gray-700 dark:text-darkmodeFots"
                        >
                          {formateDate(all[tableData.code])}
                        </td>
                      );
                    }

                    if (tableData.formatFunction === "formatTime") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal text-gray-700 dark:text-darkmodeFots"
                        >
                          {formatTime(all[tableData.code])}
                        </td>
                      );
                    }
                    if (tableData.formatFunction === "smallString") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-medium text-slate-600 text dark:text-darkmodeFots"
                        >
                          {smallString(
                            all[tableData.code],
                            tableData.stringClip
                          )}
                        </td>
                      );
                    }
                    if (tableData.link) {
                      return (
                        <Link
                          key={index}
                          to={`${tableData.href}/${all[tableData.code]}`}
                        >
                          <td className="py-2 pl-6 text-xs font-medium text-blue-600 text dark:text-darkmodeFots">
                            {all[tableData.code]}
                          </td>
                        </Link>
                      );
                    }

                    if (
                      all[tableData.code] === "active" ||
                      all[tableData.code] === "settlement" ||
                      all[tableData.code] === "profit"
                    ) {
                      return (
                        <td
                          title={all["phrase"] || ""}
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-green-500 rounded-md">
                            {all[tableData.code]}
                          </span>
                        </td>
                      );
                    }

                    if (tableData.type === "number") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span
                            className={`px-2 py-1 ${
                              colors[Math.floor(Math.random() * colors.length)]
                            } text-white capitalize rounded-md`}
                          >
                            {all[tableData.code]} $
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === "hidden") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span
                            className={`px-2 py-1 ${
                              all[tableData.code] === "hidden"
                                ? "bg-red-500"
                                : "bg-green-500"
                            } text-white capitalize rounded-md`}
                          >
                            {all[tableData.code]}
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === "Y") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-green-500 rounded-md">
                            Active
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === false) {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-xl text-white capitalize rounded-md">
                            ✅
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === true) {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-red-500 rounded-md">
                            X
                          </span>
                        </td>
                      );
                    }
                    if (tableData.pillsShow) {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-green-500 rounded-md">
                            {all[tableData.code]}
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === "Add") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-green-500 rounded-md">
                            Add
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === "deposit") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-purple-500 rounded-md">
                            {all[tableData.code]}
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === "confirmed") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-green-500 rounded-md">
                            {all[tableData.code]}
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === "pending") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-gray-500 rounded-md">
                            {all[tableData.code]}
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === "disabled") {
                      return (
                        <td
                          title={all["phrase"] || ""}
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-red-500 rounded-md">
                            {all[tableData.code]}
                          </span>
                        </td>
                      );
                    }

                    if (
                      all[tableData.code] === "N" ||
                      all[tableData.code] === "rejected"
                    ) {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-red-500 rounded-md">
                            Rejected
                          </span>
                        </td>
                      );
                    }

                    if (
                      all[tableData.code] === "Withdraw" ||
                      all[tableData.code] === "withdraw"
                    ) {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-red-500 rounded-md">
                            Withdraw
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === "inactive") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-red-500 rounded-md">
                            {all[tableData.code]}
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === "Rejected") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-red-500 rounded-md">
                            {all[tableData.code]}
                          </span>
                        </td>
                      );
                    }
                    return (
                      <td
                        key={index}
                        className={` font-normal ${
                          tableData.textCenter ? "text-center" : "pl-6"
                        } text-gray-700  text-xs py-2 dark:text-darkmodeFots`}
                      >
                        {all[tableData.code]}
                      </td>
                    );
                  })}
                  {props.tableActionHeader && (
                    <ActionIcons
                      hideDeleteIcon={props.hideDeleteIcon}
                      slug={all["slug"]}
                      time={all["updatedAt"]}
                      actionTableAllOptions={props.actionTableAllOptions}
                      hideUpdateIcon={props.hideUpdateIcon}
                      updateLinkString={props.updateLinkString}
                      updateLinkTerm={
                        props.updateLinkTerm === "id" ? all._id : all.slug
                      }
                      approveLinkString={props.approveLinkString}
                      approveLinkTerm={
                        props.approveLinkTerm === "id" ? all._id : all.slug
                      }
                      rejectLinkString={props.rejectLinkString}
                      rejectLinkTerm={
                        props.rejectLinkTerm === "id" ? all._id : all.slug
                      }
                      deleteLinkString={props.deleteLinkString}
                      deleteLinkTerm={
                        props.deleteLinkTerm === "id" ? all._id : all.slug
                      }
                      rejectTransaction={props.rejectTransaction}
                      approveTransaction={props.approveTransaction}
                      addProfitTransaction={props.addProfitTransaction}
                      addProfitLinkString={props.addProfitLinkString}
                      addProfitLinkTerm={
                        props.addProfitLinkTerm === "id" ? all._id : all.slug
                      }
                      updateParameters={props.updateParameters}
                      updateParametersLinkString={
                        props.updateParametersLinkString
                      }
                      updateParametersLinkTerm={
                        props.updateParametersLinkTerm === "id"
                          ? all._id
                          : all.slug
                      }
                      depositParameters={props.depositParameters}
                      depositParametersLinkString={
                        props.depositParametersLinkString
                      }
                      depositParametersLinkTerm={
                        props.depositParametersLinkTerm === "id"
                          ? all._id
                          : all.slug
                      }
                    />
                  )}
                </tr>
              );
            })
          : array &&
            array.length > 0 &&
            array.map((all, index) => {
              return (
                <tr
                  key={index}
                  className="pl-6 even:bg-gray-100 dark:bg-darkmodeSecoundry"
                >
                  {props.tableHeaderArray.map((tableData, index) => {
                    if (tableData.formatFunction === "formatDate") {
                      return (
                        <td
                          key={index}
                          title={formatTime(all[tableData.code])}
                          className="py-2 pl-6 text-xs font-normal text-gray-700 dark:text-darkmodeFots"
                        >
                          {formateDate(all[tableData.code])}
                        </td>
                      );
                    }

                    if (tableData.formatFunction === "formatTime") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal text-gray-700 dark:text-darkmodeFots"
                        >
                          {formatTime(all[tableData.code])}
                        </td>
                      );
                    }
                    if (tableData.formatFunction === "smallString") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-medium text-slate-600 text dark:text-darkmodeFots"
                        >
                          {smallString(
                            all[tableData.code],
                            tableData.stringClip
                          )}
                        </td>
                      );
                    }
                    if (tableData.link) {
                      return (
                        <Link
                          key={index}
                          to={`${tableData.href}/${all[tableData.code]}`}
                        >
                          <td className="py-2 pl-6 text-xs font-medium text-blue-600 text dark:text-darkmodeFots">
                            {all[tableData.code]}
                          </td>
                        </Link>
                      );
                    }

                    if (
                      all[tableData.code] === "active" ||
                      all[tableData.code] === "settlement" ||
                      all[tableData.code] === "profit"
                    ) {
                      return (
                        <td
                          title={all["phrase"] || ""}
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-green-500 rounded-md">
                            {all[tableData.code]}
                          </span>
                        </td>
                      );
                    }

                    if (tableData.type === "number") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span
                            className={`px-2 py-1 ${
                              colors[Math.floor(Math.random() * colors.length)]
                            } text-white capitalize rounded-md`}
                          >
                            {all[tableData.code]} $
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === "hidden") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span
                            className={`px-2 py-1 ${
                              all[tableData.code] === "hidden"
                                ? "bg-red-500"
                                : "bg-green-500"
                            } text-white capitalize rounded-md`}
                          >
                            {all[tableData.code]}
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === "Y") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-green-500 rounded-md">
                            Active
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === false) {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-xl text-white capitalize rounded-md">
                            ✅
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === true) {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-red-500 rounded-md">
                            X
                          </span>
                        </td>
                      );
                    }
                    if (tableData.pillsShow) {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-green-500 rounded-md">
                            {all[tableData.code]}
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === "Add") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-green-500 rounded-md">
                            Add
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === "deposit") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-purple-500 rounded-md">
                            {all[tableData.code]}
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === "confirmed") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-green-500 rounded-md">
                            {all[tableData.code]}
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === "pending") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-gray-500 rounded-md">
                            {all[tableData.code]}
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === "disabled") {
                      return (
                        <td
                          title={all["phrase"] || ""}
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-red-500 rounded-md">
                            {all[tableData.code]}
                          </span>
                        </td>
                      );
                    }

                    if (
                      all[tableData.code] === "N" ||
                      all[tableData.code] === "rejected"
                    ) {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-red-500 rounded-md">
                            Rejected
                          </span>
                        </td>
                      );
                    }

                    if (
                      all[tableData.code] === "Withdraw" ||
                      all[tableData.code] === "withdraw"
                    ) {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-red-500 rounded-md">
                            Withdraw
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === "inactive") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-red-500 rounded-md">
                            {all[tableData.code]}
                          </span>
                        </td>
                      );
                    }
                    if (all[tableData.code] === "Rejected") {
                      return (
                        <td
                          key={index}
                          className="py-2 pl-6 text-xs font-normal dark:text-darkmodeFots"
                        >
                          <span className="px-2 py-1 text-white capitalize bg-red-500 rounded-md">
                            {all[tableData.code]}
                          </span>
                        </td>
                      );
                    }
                    return (
                      <td
                        key={index}
                        className={` font-normal ${
                          tableData.textCenter ? "text-center" : "pl-6"
                        } text-gray-700  text-xs py-2 dark:text-darkmodeFots`}
                      >
                        {all[tableData.code]}
                      </td>
                    );
                  })}
                  {props.tableActionHeader && (
                    <ActionIcons
                      hideDeleteIcon={props.hideDeleteIcon}
                      slug={all["slug"]}
                      time={all["updatedAt"]}
                      actionTableAllOptions={props.actionTableAllOptions}
                      hideUpdateIcon={props.hideUpdateIcon}
                      updateLinkString={props.updateLinkString}
                      updateLinkTerm={
                        props.updateLinkTerm === "id" ? all._id : all.slug
                      }
                      approveLinkString={props.approveLinkString}
                      approveLinkTerm={
                        props.approveLinkTerm === "id" ? all._id : all.slug
                      }
                      rejectLinkString={props.rejectLinkString}
                      rejectLinkTerm={
                        props.rejectLinkTerm === "id" ? all._id : all.slug
                      }
                      deleteLinkString={props.deleteLinkString}
                      deleteLinkTerm={
                        props.deleteLinkTerm === "id" ? all._id : all.slug
                      }
                      rejectTransaction={props.rejectTransaction}
                      approveTransaction={props.approveTransaction}
                      addProfitTransaction={props.addProfitTransaction}
                      addProfitLinkString={props.addProfitLinkString}
                      addProfitLinkTerm={
                        props.addProfitLinkTerm === "id" ? all._id : all.slug
                      }
                      updateParameters={props.updateParameters}
                      updateParametersLinkString={
                        props.updateParametersLinkString
                      }
                      updateParametersLinkTerm={
                        props.updateParametersLinkTerm === "id"
                          ? all._id
                          : all.slug
                      }
                      depositParameters={props.depositParameters}
                      depositParametersLinkString={
                        props.depositParametersLinkString
                      }
                      depositParametersLinkTerm={
                        props.depositParametersLinkTerm === "id"
                          ? all._id
                          : all.slug
                      }
                    />
                  )}
                </tr>
              );
            })}
      </tbody>
    </table>
  );
};

export default DynamicTable;
