import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import TableHeader from "../../components/TableHeader";
import { performSearchQuery } from "../../utils/helpers/helper";
import DynamicTable from "../../components/DynamicTable";
import Pagination from "../../components/Pagination";
import {
  getAllPartnersRequest,
  setErrorEmpty,
} from "../../features/partnerSlice";
import allTableHeaders from "../../tableHeaders/AllTableHeaders";
import { setMessage, appLoading } from "../../features/appSlice";

const PartnersList = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(20);
  const partnersArray = useSelector((state) => state.partners.partnersList);
  const searchArray = performSearchQuery(
    partnersArray,
    search,
    "fullName",
    "username"
  );
  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentResultsToMap =
    partnersArray &&
    partnersArray.length > 0 &&
    partnersArray.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    dispatch(getAllPartnersRequest());
    dispatch(setMessage(""));
    dispatch(setErrorEmpty());
    dispatch(appLoading(false));
  }, [dispatch]);
  useEffect(() => {}, [postsPerPage]);

  return (
    <div className="dark:bg-tableclmn bg-secoundry shadow-dhruv mt-4 font-semibold text-iconColor">
      <TableHeader
        ButtonName="Add New Partner"
        routs="/partners-menu/add-new-partner"
        setSearch={setSearch}
        postsPerPage={postsPerPage}
        setPostsPerPage={setPostsPerPage}
        search={search}
        heading={"Partner's List"}
        dataArray={partnersArray}
      />
      {partnersArray && partnersArray.length > 0 ? (
        <div>
          <DynamicTable
            mappingDataArray={currentResultsToMap}
            tableHeaderArray={allTableHeaders.partnerTableHeader}
            tableActionHeader={true}
            search={search}
            searchArray={searchArray}
            updateLinkString="/partners-menu/update-partner"
            updateLinkTerm="id"
            hideDeleteIcon={true}
            addProfitTransaction={true}
            addProfitLinkTerm="id"
            addProfitLinkString="/partners-menu/add-profit-to-partner"
          />
          <Pagination
            currentPage={currentPage}
            postsPerPage={postsPerPage}
            totalPosts={partnersArray && partnersArray.length}
            paginate={paginate}
          />
        </div>
      ) : (
        <div className="flex py-32">
          <div className="m-auto ">
            <p className="text-sm font-normal text-gray-700 mb-2 text-center">
              No Records
            </p>
            <Link to="/partners-menu/add-new-partner">
              <button className="bg-yellow-600 text-sm px-6 py-1 text-white rounded-sm hover:bg-yellow-500">
                Add New Partner
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default PartnersList;
