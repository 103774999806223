import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { customersAPI } from "../api/customerAPI";
import { setMessage, appLoading } from "./appSlice";

export const getAllCustomersRequest = createAsyncThunk(
  "customers/getAllCustomersRequest",
  async (all, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const list = await customersAPI.getAllCustomers();
      dispatch(getCustomersList(list.customers));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const addNewCustomerRequest = createAsyncThunk(
  "customers/addNewCustomerRequest",
  async (createData, { dispatch, rejectWithValue }) => {
    try {
      const data = await customersAPI.addNewCustomer(createData);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);
// only single customer account
export const singleCustomerRequest = createAsyncThunk(
  "customers/singleCustomerRequest",
  async (leadId, { dispatch, rejectWithValue }) => {
    try {
      const data = await customersAPI.getSingleCustomer(leadId);
      dispatch(setSingleCustomer(data));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const updateCustomerRequest = createAsyncThunk(
  "customers/updateCustomerRequest",
  async (updateData, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await customersAPI.updateCustomerDetails(updateData);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// transactions starts here3
export const getAllTransactionsRequest = createAsyncThunk(
  "customers/getAllTransactionsRequest =",
  async (leadId, { dispatch, rejectWithValue }) => {
    try {
      const data = await customersAPI.getAllCustomerTransactions();
      dispatch(setAllTransactions(data.transactions));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);
export const addProfitToCustomerRequest = createAsyncThunk(
  "customers/addProfitToCustomerRequest",
  async (pf, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await customersAPI.addProfitCustomer(pf);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const getAllPendingTransactionsRequest = createAsyncThunk(
  "customers/getAllPendingTransactionsRequest",
  async (leadId, { dispatch, rejectWithValue }) => {
    try {
      const data = await customersAPI.getAllPendingCustomerTransactions();
      dispatch(setPendingTransactions(data.pendingTransactions));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const getAllRejectedTransactionsRequest = createAsyncThunk(
  "customers/getAllRejectedTransactionsRequest",
  async (leadId, { dispatch, rejectWithValue }) => {
    try {
      const data = await customersAPI.getAllRejectedCustomerTransactions();
      dispatch(setRejectedTransactions(data.rejectedTransactions));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const approveWithdrawlTransactionRequest = createAsyncThunk(
  "customers/approveWithdrawlTransactionRequest",
  async (subject, { dispatch, rejectWithValue }) => {
    try {
      const data = await customersAPI.approveWithdrawlTransaction(subject);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const approveDepositTransactionRequest = createAsyncThunk(
  "customers/approveDepositTransactionRequest",
  async (subject, { dispatch, rejectWithValue }) => {
    try {
      const data = await customersAPI.approveDepositTransaction(subject);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// Get Single customer Transaction
export const singleCustomerTransaction = createAsyncThunk(
  "customers/singleCustomerTransaction",
  async (leadId, { dispatch, rejectWithValue }) => {
    try {
      const data = await customersAPI.singleCustomerTransaction(leadId);
      dispatch(setSingleCustomerTransaction(data));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// Get Single customer Account
export const singleAccountInfoRequest = createAsyncThunk(
  "customers/singleAccountInfoRequest",
  async (leadId, { dispatch, rejectWithValue }) => {
    try {
      const data = await customersAPI.singleCustomerAccount(leadId);
      dispatch(setSingleCustomerAccount(data));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// Update used Margin , Min Profit , max Profit
export const changeCustomerParametersRequest = createAsyncThunk(
  "customers/changeCustomerParametersRequest",
  async (dataToUpdate, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await customersAPI.updateParameters(dataToUpdate);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// deposit equity to customer account
export const depositEquityToCustomerRequest = createAsyncThunk(
  "customers/depositEquityToCustomerRequest",
  async (dataToUpdate, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await customersAPI.equityDeposit(dataToUpdate);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// Get All Accounts with All Parameters
export const getAllCustomerAccountsWithParametersRequest = createAsyncThunk(
  "customers/getAllCustomerAccountsWithParametersRequest",
  async (dataToUpdate, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await customersAPI.getAllCustomerAccountsWithParameters();
      dispatch(setAllAccountsWithParameters(data));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// Get All Accounts with All Parameters
export const getCustomerWithAccountRequest = createAsyncThunk(
  "customers/getCustomerWithAccountRequest",
  async (dataToUpdate, { dispatch, rejectWithValue }) => {
    try {
      const data = await customersAPI.singleCustomerWithAccount(dataToUpdate);
      dispatch(setCustomerWithAccountDetail(data));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

const initialState = {
  customersList: [],
  pendingTransactions: [],
  allTransactions: [],
  singleCustomer: {},
  singleCustomerTransaction: {},
  singleAccount: {},
  hasError: "",
  usernameStatus: false,
  rejectedTransactions: [],
  allAccounts: [],
  customerWithAccountDetail: {},
};

export const customerSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setAllAccountsWithParameters: (state, action) => {
      state.allAccounts = action.payload;
    },
    getCustomersList: (state, action) => {
      state.customersList = action.payload;
    },
    setCustomerWithAccountDetail: (state, action) => {
      state.customerWithAccountDetail = action.payload;
    },
    setPendingTransactions: (state, action) => {
      state.pendingTransactions = action.payload;
    },
    setRejectedTransactions: (state, action) => {
      state.rejectedTransactions = action.payload;
    },
    setAllTransactions: (state, action) => {
      state.allTransactions = action.payload;
    },
    setSingleCustomer: (state, action) => {
      state.singleCustomer = action.payload;
    },
    setSingleCustomerAccount: (state, action) => {
      state.singleAccount = action.payload;
    },
    setSingleCustomerTransaction: (state, action) => {
      state.singleCustomerTransaction = action.payload;
    },
    setUsernameStatus: (state, action) => {
      state.usernameStatus = action.payload;
    },
    setErrorEmpty: (state) => {
      state.hasError = "";
    },
  },
  extraReducers: {
    [getAllCustomersRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [addNewCustomerRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [singleCustomerRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [updateCustomerRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [getAllTransactionsRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [addProfitToCustomerRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [getAllPendingTransactionsRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [getAllRejectedTransactionsRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [approveWithdrawlTransactionRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [singleCustomerTransaction.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [approveDepositTransactionRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [singleAccountInfoRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [changeCustomerParametersRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [getAllCustomerAccountsWithParametersRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [getCustomerWithAccountRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [depositEquityToCustomerRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
  },
});
export const {
  setCustomerWithAccountDetail,
  getCustomersList,
  setSingleCustomer,
  setErrorEmpty,
  setUsernameStatus,
  setPendingTransactions,
  setAllTransactions,
  setSingleCustomerTransaction,
  setRejectedTransactions,
  setSingleCustomerAccount,
  setAllAccountsWithParameters,
} = customerSlice.actions;

export default customerSlice.reducer;
