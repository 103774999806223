import * as yup from "yup";

const validationSchemas = {
  registerPartnerValidation: yup.object({
    fullName: yup.string().required("Name can't be blank").min(3).max(30),
    username: yup
      .string()
      .required("Username can't be blank")
      .max(15, "Maximum 15 charactors")
      .min("3", "Minimum 3 chararctors"),
    email: yup.string().email().required("Email can't be blank"),
    password: yup
      .string()
      .required("Pasword can't be blank")
      .min(4, "Password must be minimum 4 characters")
      .max(20),
  }),
  registerCustomerValidation: yup.object({
    fullName: yup.string().required("Name can't be blank").min(3).max(30),
    username: yup
      .string()
      .required("Username can't be blank")
      .max(15, "Maximum 15 charactors")
      .min("3", "Minimum 3 chararctors"),
    email: yup.string().email().required("Email can't be blank"),
    password: yup
      .string()
      .required("Pasword can't be blank")
      .min(4, "Password must be minimum 4 characters")
      .max(20),
    isDocumentVerified: yup.boolean(),
  }),
  updateCustomerValidation: yup.object({
    fullName: yup.string().required("Name can't be blank").min(3).max(30),
    email: yup.string().email().required("Email can't be blank"),
    isDocumentVerified: yup.boolean(),
  }),
  addProfitIsNumberValidation: yup.object({
    profit: yup
      .number("profit must be a number")
      .required("Profit is required field"),
  }),
  parameterValidation: yup.object({
    usedMargin: yup
      .number("Used Margin must be a number")
      .required("Used Margin is required field"),
    minimumfloatingProfitLoss: yup
      .number("Minimum Floating must be a number")
      .required("Minimum Floating is required field"),
    maximumfloatingProfitLoss: yup
      .number("Maximum Floating must be a number")
      .required("Maximum Floating is required field"),
  }),
  updatePartnerValidation: yup.object({
    fullName: yup.string().required("Name can't be blank").min(3).max(30),
    email: yup.string().email().required("Email can't be blank"),
  }),
  adValidation: yup.object({
    ownerName: yup.string().required("Owner Name can't be blank"),
    adsTitle: yup.string().required("Ad Title can't be blank"),
    validToDate: yup.string().required("Valid To Date can't be blank"),
    validFromDate: yup.string().required("Valid from Date can't be blank"),
    whatsApp: yup
      .number()
      .required("10 Digits number required")
      .min(10, "10 Digits required"),
    mobile: yup.number().required("10 Digits number required"),
  }),

  notificationValidation: yup.object({
    title: yup.string().required("Notification title can't be empty"),
  }),

  faqSchema: yup.object({
    question: yup.string().required("Faq Question can't be blank"),
    answer: yup.string().required("Faq answer can't be blank"),
  }),
  loginSchema: yup.object({
    email: yup.string().email().required("Email can't be blank"),
    password: yup.string().required("Password can't be blank"),
  }),
  categorySchema: yup.object({
    categoryName: yup.string().required("Category Name can't be blank"),
  }),
  tableSchema: yup.object({
    tableName: yup.string().required("Table Name can't be blank"),
  }),
  tagSchema: yup.object({
    tagName: yup.string().required("Tag Name can't be blank"),
  }),
  termSchema: yup.object({
    title: yup.string().required("Title can't be blank"),
    term: yup.string().required("Term can't be blank"),
  }),
  blogValidationSchema: yup.object({
    title: yup.string().required("Title can't be blank"),
  }),
  linkSchema: yup.object({
    listUrl: yup.string().required("Links can't be blank"),
  }),
};

export default validationSchemas;
