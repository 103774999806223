import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  approveTransactionRequest,
  singlePartnerTransaction,
} from "../../features/partnerSlice";
import ApproveModal from "../../components/modals/ApproveModal";

const ApproveTransaction = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const deletemessage = useSelector((state) => state.app.message);
  const singleTransaction = useSelector(
    (state) => state.partners.singlePartnerTransaction
  );

  const deleteF = () => {
    let allData = {
      status: props.reject ? "rejected" : "confirmed",
      id,
    };
    dispatch(approveTransactionRequest(allData));
  };

  useEffect(() => {
    dispatch(singlePartnerTransaction(id));
  }, [dispatch, id]);

  return (
    <ApproveModal
      message={deletemessage}
      cancelLink="/partners-menu"
      deleteFunction={deleteF}
      amount={singleTransaction.amount}
      client={singleTransaction.username}
      reject={props.reject}
    />
  );
};

export default ApproveTransaction;
