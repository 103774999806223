import React from "react";
import { BsTrash, BsFillCheckCircleFill } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import { GiTakeMyMoney } from "react-icons/gi";
import { MdBlock, MdAttachMoney } from "react-icons/md";
import {
  AiOutlineEye,
  AiOutlineCloseCircle,
  AiOutlinePlusCircle,
  AiOutlineBank,
} from "react-icons/ai";
import { Link } from "react-router-dom";

const ActionIcons = (props) => {
  return (
    <td className=" flex justify-center items-center text-sm py-2 px-20  ">
      {props.actionTableAllOptions && (
        <div
          onClick={props.view}
          className="p-2 group hover:bg-gray-100 rounded-full"
        >
          {Date.now() / 1000 > new Date(props.time).getTime() / 1000 + 300 ? (
            // eslint-disable-next-line
            <a href={`${process.env.CLIENT_URL}/${props.slug}`} target="_blank">
              <AiOutlineEye
                title="Click to Open Blog"
                className="font-normal text-xl group-hover:text-blue-500 cursor-pointer dark:text-darkmodeFots"
              />
            </a>
          ) : (
            <MdBlock
              title="Wait !!"
              className="font-normal text-xl group-hover:text-red-500 dark:text-darkmodeFots"
            />
          )}
        </div>
      )}
      {!props.hideUpdateIcon && (
        <Link to={`${props.updateLinkString}/${props.updateLinkTerm}`}>
          <div
            title="Update"
            className="p-2 group hover:bg-gray-100 rounded-full mx-1"
          >
            <FaEdit className="font-normal text-xl group-hover:text-blue-500 dark:text-darkmodeFots" />
          </div>
        </Link>
      )}
      {!props.hideDeleteIcon && (
        <Link to={`${props.deleteLinkString}/${props.deleteLinkTerm}`}>
          <div className="p-2 group hover:bg-gray-100 rounded-full">
            <BsTrash className="font-normal text-xl text-red-600 group-hover:text-blue-500 dark:text-darkmodeFots" />
          </div>
        </Link>
      )}

      {props.approveTransaction && (
        <Link to={`${props.approveLinkString}/${props.approveLinkTerm}`}>
          <div
            title="Approve"
            className="p-2 group hover:bg-gray-100 rounded-full"
          >
            <BsFillCheckCircleFill className="font-normal text-xl text-green-600 group-hover:text-blue-500 dark:text-darkmodeFots" />
          </div>
        </Link>
      )}

      {props.rejectTransaction && (
        <Link to={`${props.rejectLinkString}/${props.rejectLinkTerm}`}>
          <div
            title="Reject"
            className="p-2 group hover:bg-gray-100 rounded-full"
          >
            <AiOutlineCloseCircle className="font-normal text-xl text-red-600 group-hover:text-blue-500 dark:text-darkmodeFots" />
          </div>
        </Link>
      )}
      {props.showBankDetail && (
        <Link to={`${props.bankString}/${props.bankDetailId}`}>
          <div
            title="Check Bank Details"
            className="p-2 group hover:bg-gray-100 rounded-full"
          >
            <AiOutlineBank className="font-normal text-xl text-red-600 group-hover:text-blue-500 dark:text-darkmodeFots" />
          </div>
        </Link>
      )}
      {props.addProfitTransaction && (
        <Link to={`${props.addProfitLinkString}/${props.addProfitLinkTerm}`}>
          <div
            title="Add Profit"
            className="p-2 group hover:bg-gray-100 rounded-full"
          >
            <AiOutlinePlusCircle className="font-normal text-xl text-red-600 group-hover:text-blue-500 dark:text-darkmodeFots" />
          </div>
        </Link>
      )}

      {props.updateParameters && (
        <Link
          to={`${props.updateParametersLinkString}/${props.updateParametersLinkTerm}`}
        >
          <div
            title="Update Used Margin"
            className="p-2 group hover:bg-gray-100 rounded-full"
          >
            <MdAttachMoney className="font-normal text-xl text-red-600 group-hover:text-blue-500 dark:text-darkmodeFots" />
          </div>
        </Link>
      )}
      {props.depositParameters && (
        <Link
          to={`${props.depositParametersLinkString}/${props.depositParametersLinkTerm}`}
        >
          <div
            title="Deposit Equity"
            className="p-2 group hover:bg-gray-100 rounded-full"
          >
            <GiTakeMyMoney className="font-normal text-xl text-green-600 group-hover:text-blue-500 dark:text-darkmodeFots" />
          </div>
        </Link>
      )}
    </td>
  );
};

export default ActionIcons;
