import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

/**
 * <DeleteModal message={dynamicMessageAfter_API_CALl} cancelLink={LinkBacktoList} deleteFunction={function_for_delete_request}/>
 */
const BankDetailModal = (props) => {
  const { bankDetails } = props.transaction;
  return (
    <div className="fixed z-50 top-0 left-0 right-0 bottom-0 bg-slate-800 bg-opacity-90 overflow-hidden">
      <div
        // style={{ top: "25%", bottom: "25%", left: "34%", right: "34%" }}
        className="bg-white bg-opacity-100 top-[25%] bottom-[25%] left-[5%] right-[5%] absolute rounded-md lg:top-[23%] lg:bottom-[23%] lg:left-[30%] lg:right-[30%]"
      >
        <div className="flex justify-between items-center border-b px-4 pt-4 pb-1 border-slate-300">
          <p className="text-sm font-semibold text-gray-600">Notification</p>
          <Link to={props.cancelLink}>
            <AiOutlineCloseCircle className=" text-indigo-500 text-2xl hover:text-red-500 cursor-pointer" />
          </Link>
        </div>
        {bankDetails && (
          <div className="text-center mt-12">
            <h3>Bank Name : {bankDetails?.bankName}</h3>
            <h3>Account Name : {bankDetails?.accountName}</h3>
            <h3>Account Number : {bankDetails?.accountNumber}</h3>
            <h3>IFSC : {bankDetails?.ifsc}</h3>
            <h3>Contact : {bankDetails?.contact}</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default BankDetailModal;
