import React from "react";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "../PrivateRoute";
import DashBoard from "../../pages/DashBoard";
import Login from "../../pages/LoginPages/LoginComp";
import Logout from "../../shared/loader/Logout";
import PartnersList from "../../pages/partners/PartnersList";
import PartnerMenu from "../../pages/partners/PartnerMenu";
import AddNewPartner from "../../pages/partners/AddNewPartner";
import UpdatePartner from "../../pages/partners/UpdatePartner";
import UpdateCustomer from "../../pages/customer/UpdateCustomer";
import CustomerMenu from "../../pages/customer/CustomerMenu";
import PartnerTransactions from "../../pages/partners/PartnerTransactions";
import PendingTransactions from "../../pages/partners/PendingTransactions";
import ApproveTransaction from "../../pages/partners/ApproveTransaction";
import ApproveCustomerTransaction from "../../pages/customer/ApproveTransaction";
import ShowBankDetail from "../../pages/customer/ShowBankDetail";
import RejectedTransactions from "../../pages/partners/RejectedTransactions";
import AddProfitToPartner from "../../pages/partners/AddProfitToPartner";
import CustomersList from "../../pages/customer/CustomersList";
import CustomerTransactions from "../../pages/customer/CustomerTransactions";
import PendingCustomerTransactions from "../../pages/customer/PendingTransactions";
import RejectedCustomerTransactions from "../../pages/customer/RejectedTransactions";
import AddNewCustomer from "../../pages/customer/AddNewCustomer";
import AddProfitToCustomer from "../../pages/customer/AddProfitToCustomer";
import AdjustParameters from "../../pages/customer/AdjustParameters";
import ParametersList from "../../pages/customer/ParametersList";
import AddDeposit from "../../pages/customer/AddDeposit";

const AllRoutes = (props) => {
  return (
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route
        exact
        path="/dashboard"
        element={
          <PrivateRoute>
            <DashBoard show={props.show} />
          </PrivateRoute>
        }
      />

      {/* Clients Routes STARTS here */}
      <Route
        exact
        path="/partners-menu"
        element={
          <PrivateRoute>
            <PartnerMenu />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/partners-menu/list-partners"
        element={
          <PrivateRoute>
            <PartnersList />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/partners-menu/add-new-partner"
        element={
          <PrivateRoute>
            <AddNewPartner />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/partners-menu/update-partner/:id"
        element={
          <PrivateRoute>
            <UpdatePartner />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/partners-menu/all-transactions"
        element={
          <PrivateRoute>
            <PartnerTransactions />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/partners-menu/pending-transactions"
        element={
          <PrivateRoute>
            <PendingTransactions />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/partners-menu/rejected-transactions"
        element={
          <PrivateRoute>
            <RejectedTransactions />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/partners-menu/add-profit-to-partner/:id"
        element={
          <PrivateRoute>
            <AddProfitToPartner />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/partners-menu/approve-transaction/:id"
        element={
          <PrivateRoute>
            <ApproveTransaction />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/partners-menu/reject-transaction/:id"
        element={
          <PrivateRoute>
            <ApproveTransaction reject={true} />
          </PrivateRoute>
        }
      />

      {/* Partners Routes ENDS here */}

      {/* Customer Routes STARTS here */}
      <Route
        exact
        path="/customers-menu"
        element={
          <PrivateRoute>
            <CustomerMenu />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/customers-menu/list-customers"
        element={
          <PrivateRoute>
            <CustomersList />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/customers-menu/add-new-customer"
        element={
          <PrivateRoute>
            <AddNewCustomer />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/customers-menu/update-customer/:id"
        element={
          <PrivateRoute>
            <UpdateCustomer />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/customers-menu/all-accounts"
        element={
          <PrivateRoute>
            <ParametersList />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/customers-menu/update-parameters/:id"
        element={
          <PrivateRoute>
            <AdjustParameters />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/customers-menu/add-deposit/:id"
        element={
          <PrivateRoute>
            <AddDeposit />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/customers-menu/all-transactions"
        element={
          <PrivateRoute>
            <CustomerTransactions />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/customers-menu/pending-transactions"
        element={
          <PrivateRoute>
            <PendingCustomerTransactions />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/customers-menu/rejected-transactions"
        element={
          <PrivateRoute>
            <RejectedCustomerTransactions />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/customers-menu/add-profit-to-customer/:id"
        element={
          <PrivateRoute>
            <AddProfitToCustomer />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/customers-menu/approve-transaction/:id"
        element={
          <PrivateRoute>
            <ApproveCustomerTransaction />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/customers-menu/reject-transaction/:id"
        element={
          <PrivateRoute>
            <ApproveCustomerTransaction reject={true} />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/customers-menu/show-bank-detail/:id"
        element={
          <PrivateRoute>
            <ShowBankDetail />
          </PrivateRoute>
        }
      />

      {/* Customer Routes Ends here */}
      <Route
        exact
        path="/logout"
        element={<Logout cancelLink="/dashboard" />}
      />
    </Routes>
  );
};

export default AllRoutes;
