import { BiHome } from "react-icons/bi";
import { FiLogOut, FiUserPlus } from "react-icons/fi";
import { MdNotificationsNone } from "react-icons/md";

export const sideBarData = [
  {
    title: "Home",
    icon: (
      <BiHome className="text-lg text-iconColor group-hover:text-botton dark:text-darkmodeFots" />
    ),
    path: "/dashboard",
  },
  {
    title: "Partners",
    icon: (
      <MdNotificationsNone className=" text-lg text-iconColor group-hover:text-botton dark:text-darkmodeFots" />
    ),
    path: "/partners-menu",
  },
  {
    title: "Customers",
    icon: (
      <FiUserPlus className=" text-lg text-iconColor group-hover:text-botton dark:text-darkmodeFots" />
    ),
    path: "/customers-menu",
  },

  {
    title: "Logout",
    icon: (
      <FiLogOut className=" text-lg   text-iconColor group-hover:text-botton dark:text-darkmodeFots" />
    ),
    path: "/logout",
  },
];
