import { configureStore } from "@reduxjs/toolkit";
import appReducer from "../features/appSlice";
import partnerReducer from "../features/partnerSlice";
import customerReducer from "../features/customerSlice";
import loginReducer from "../features/loginSlice";
import adminReducer from "../features/adminSlice";
import paginationReducer from "../features/paginationSlice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    partners: partnerReducer,
    customers: customerReducer,
    login: loginReducer,
    user: adminReducer,
    pages: paginationReducer,
  },
});
