import React from "react";
import { Link } from "react-router-dom";

const Menu = (props) => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-5 gap-2 grid-flow-row lg:pl-2 w-[97%]">
      {props.menuItems &&
        props.menuItems.length > 0 &&
        props.menuItems.map((item, index) => {
          const { Icon, name, link } = item;
          return (
            <Link key={index} to={link}>
              <div className="border group dark:bg-gray-700 dark:border-gray-800 dark:text-gray-300 hover:bg-indigo-600 shadow-white w-full flex justify-center lg:justify-around items-center m-2 p-4 rounded-md bg-gray-50">
                <Icon className="text-base dark:text-gray-300 group-hover:text-white font-semibold" />
                <h5 className="text-xs pl-4 lg:pl-0 dark:text-gray-300 font-semibold text-gray-600 group-hover:text-white">
                  {name}
                </h5>
              </div>
            </Link>
          );
        })}
    </div>
  );
};

export default Menu;
