import React from "react";
import Menu from "../../components/Menu";
import { menuWithIcon } from "../../tableHeaders/menuWithIcon";

const CustomerMenu = () => {
  return (
    <div
      style={{ minHeight: "80vh" }}
      className="mt-4 bg-secoundry shadow-dhruv rounded cursor-pointer dark:bg-darkmodeSecoundry "
    >
      <h3 className="px-5 py-2 pt-4 text-indigo-500 text-base font-semibold">
        Customer's Menu
      </h3>
      <Menu menuItems={menuWithIcon.customerMenu} />
    </div>
  );
};

export default CustomerMenu;
