import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import validationSchemas from "../../utils/validations/validationSchemas";
import CustomBtn from "../../components/CustomBtn";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MessageModal from "../../shared/loader/MessageModal";
import { setMessage } from "../../features/appSlice";
import CustomInput from "../../components/CustomInput";
import { customersAPI } from "../../api/customerAPI";
import {
  setSingleCustomer,
  updateCustomerRequest,
} from "../../features/customerSlice";
import NormalDropDown from "../../components/NormalDropDown";

const UpdateCustomer = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const message = useSelector((state) => state.app.message);
  const loading = useSelector((state) => state.app.isLoading);
  const submitError = useSelector((state) => state.customers.hasError);
  const singleCustomer = useSelector((state) => state.customers.singleCustomer);
  const [status, setStatus] = useState("");
  const [touch, setTouch] = useState(false);

  const clearNotificationMessage = () => {
    dispatch(setMessage(""));
    navigate("/customers-menu");
  };

  const onCreate = (values) => {
    let dataWithId = {
      ...values,
      status,
      id: singleCustomer._id,
    };
    dispatch(updateCustomerRequest(dataWithId));
  };

  useEffect(() => {
    async function getSingleCustomerData(customer) {
      const single = await customersAPI.getSingleCustomer(customer);
      setStatus(single.status);
      dispatch(setSingleCustomer(single));
    }
    id && getSingleCustomerData(id);
  }, [dispatch, id]);

  React.useEffect(() => {
    if (message) {
      setTimeout(() => {
        clearNotificationMessage();
      }, 4000);
    }
    // eslint-disable-next-line
  }, [message]);

  return (
    <>
      {!message ? (
        <div
          style={{ minHeight: "80vh" }}
          className="mt-4 bg-secoundry shadow-dhruv rounded dark:bg-darkmodeSecoundry "
        >
          <div className=" pb-8">
            <h2 className=" font-semibold px-4 text-lg ml-4 pt-4 text-blue-500 dark:text-darkmodeFots ">
              Update Partner
            </h2>
            <Formik
              enableReinitialize
              initialValues={{
                fullName: singleCustomer.fullName || "",
                email: singleCustomer.email || "",
                isDocumentVerified: singleCustomer.isDocumentVerified || false,
                closeAllTrades: singleCustomer.closeAllTrades || false,
              }}
              validationSchema={validationSchemas.updateCustomerValidation}
              onSubmit={onCreate}
            >
              {({ errors, touched }) => (
                <Form autoComplete="off">
                  <div
                    className={`flex justify-start md:flex-row flex-col  items-center pt-4 `}
                  >
                    <div className=" md:w-2/3 w-full  md:px-4">
                      <div className={`px-4 w-full`}>
                        <CustomInput
                          errors={errors.fullName}
                          touched={touched.fullName}
                          title="Enter Full Name"
                          name="fullName"
                          placeholder="Enter Name"
                        />
                      </div>
                    </div>
                    <div className=" md:w-2/3 w-full  md:px-6">
                      <div className={`px-2  w-full`}>
                        <CustomInput
                          errors={errors.email}
                          touched={touched.email}
                          title="Enter Email"
                          name="email"
                          placeholder="Enter Email"
                        />
                      </div>
                    </div>
                    <div className=" md:w-2/3 w-full  md:px-6 mb-5">
                      <NormalDropDown
                        text="sm" // text-xl text-xs
                        width="w-full" // w-48 , w-1/2 w-full
                        name="select" // name
                        array={["active", "disabled"]} // array to iterate
                        py="[6px]" // padding top & bottom
                        touch={touch} // touch
                        setTouch={setTouch} // set
                        label={"Status"}
                        placeholderText="Status"
                        defaultValue="Select Status"
                        value={status} //
                        setValue={setStatus} //
                      />
                    </div>
                  </div>
                  <div
                    className={`flex justify-start md:flex-row flex-col  items-center pt-4 `}
                  >
                    <div>
                      <div className=" ml-8 text-gray-600 font-medium text-sm mb-1 dark:text-gray-300">
                        Docuement Verified
                      </div>
                      <Field
                        name="isDocumentVerified"
                        type="checkbox"
                        className={`${
                          errors.isDocumentVerified &&
                          touched.isDocumentVerified
                            ? "border-red-500"
                            : "border-gray-300"
                        }   outline-none  dark:text-gray-300 border rounded py-2 font-normal transition-all duration-300 focus:border-indigo-500 dark:border-gray-500 px-3 w-full text-sm text-gray-700 caret-indigo-600 dark:bg-darkmodeSecoundry `}
                      />
                    </div>
                    <div>
                      <div className=" ml-8 text-gray-600 font-medium text-sm mb-1 dark:text-gray-300">
                        Close All Trades
                      </div>
                      <Field
                        name="closeAllTrades"
                        type="checkbox"
                        className={`${
                          errors.closeAllTrades && touched.closeAllTrades
                            ? "border-red-500"
                            : "border-gray-300"
                        }   outline-none  dark:text-gray-300 border rounded py-2 font-normal transition-all duration-300 focus:border-indigo-500 dark:border-gray-500 px-3 w-full text-sm text-gray-700 caret-indigo-600 dark:bg-darkmodeSecoundry `}
                      />
                    </div>
                  </div>

                  <div className="px-3 py-4">
                    <CustomBtn
                      type="submit"
                      text={"Update Partner"}
                      loading={loading}
                    />
                  </div>
                  {submitError && (
                    <div className="p-3 pl-8 text-red-500">{submitError}</div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <MessageModal
          open={message}
          setOpen={clearNotificationMessage}
          message={message}
        />
      )}
    </>
  );
};

export default UpdateCustomer;
