import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { partnersAPI } from "../api/partnersAPI";
import { setMessage, appLoading } from "./appSlice";

export const getAllPartnersRequest = createAsyncThunk(
  "partners/getAllPartnersRequest",
  async (all, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const list = await partnersAPI.getAllPartners();
      dispatch(getPartnersList(list.partners));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const checkUsernameRequest = createAsyncThunk(
  "partners/checkUsernameRequest",
  async (all, { dispatch, rejectWithValue }) => {
    try {
      const list = await partnersAPI.checkUsername(all);
      dispatch(setUsernameStatus(list.available));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const addNewPartnerRequest = createAsyncThunk(
  "partners/addNewPartnerRequest",
  async (createData, { dispatch, rejectWithValue }) => {
    try {
      const data = await partnersAPI.addNewPartner(createData);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const singlePartnerRequest = createAsyncThunk(
  "partners/singlePartnerRequest",
  async (leadId, { dispatch, rejectWithValue }) => {
    try {
      const data = await partnersAPI.getSinglePartner(leadId);
      dispatch(setsinglePartner(data));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const updatePartnerRequest = createAsyncThunk(
  "partners/updatePartnerRequest",
  async (updateData, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await partnersAPI.updatePartnerDetails(updateData);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// transactions starts here3
export const getAllTransactionsRequest = createAsyncThunk(
  "partners/getAllTransactionsRequest =",
  async (leadId, { dispatch, rejectWithValue }) => {
    try {
      const data = await partnersAPI.getAllPartnerTransactions();
      dispatch(setAllTransactions(data.transactions));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);
export const addProfitToPartnerRequest = createAsyncThunk(
  "partners/addProfitToPartnerRequest",
  async (pf, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await partnersAPI.addProfitPartner(pf);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const getAllPendingTransactionsRequest = createAsyncThunk(
  "partners/getAllPendingTransactionsRequest",
  async (leadId, { dispatch, rejectWithValue }) => {
    try {
      const data = await partnersAPI.getAllPendingPartnerTransactions();
      dispatch(setPendingTransactions(data.pendingTransactions));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const getAllRejectedTransactionsRequest = createAsyncThunk(
  "partners/getAllRejectedTransactionsRequest",
  async (leadId, { dispatch, rejectWithValue }) => {
    try {
      const data = await partnersAPI.getAllRejectedPartnerTransactions();
      dispatch(setRejectedTransactions(data.rejectedTransactions));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const approveTransactionRequest = createAsyncThunk(
  "partners/approveTransactionRequest",
  async (subject, { dispatch, rejectWithValue }) => {
    try {
      const data = await partnersAPI.approveTransaction(subject);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// Get Single Partner Transaction
export const singlePartnerTransaction = createAsyncThunk(
  "partners/singlePartnerTransaction",
  async (leadId, { dispatch, rejectWithValue }) => {
    try {
      const data = await partnersAPI.singlePartnerTransaction(leadId);
      dispatch(setSinglePartnerTransaction(data));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

const initialState = {
  partnersList: [],
  pendingTransactions: [],
  allTransactions: [],
  singlePartner: {},
  singlePartnerTransaction: {},
  hasError: "",
  usernameStatus: false,
  rejectedTransactions: [],
};

export const partnerSlice = createSlice({
  name: "partners",
  initialState,
  reducers: {
    getPartnersList: (state, action) => {
      state.partnersList = action.payload;
    },
    setPendingTransactions: (state, action) => {
      state.pendingTransactions = action.payload;
    },
    setRejectedTransactions: (state, action) => {
      state.rejectedTransactions = action.payload;
    },
    setAllTransactions: (state, action) => {
      state.allTransactions = action.payload;
    },
    setsinglePartner: (state, action) => {
      state.singlePartner = action.payload;
    },
    setSinglePartnerTransaction: (state, action) => {
      state.singlePartnerTransaction = action.payload;
    },
    setUsernameStatus: (state, action) => {
      state.usernameStatus = action.payload;
    },
    setErrorEmpty: (state) => {
      state.hasError = "";
    },
  },
  extraReducers: {
    [getAllPartnersRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [checkUsernameRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [addNewPartnerRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [singlePartnerRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [updatePartnerRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [getAllTransactionsRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [addProfitToPartnerRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [getAllPendingTransactionsRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [getAllRejectedTransactionsRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [approveTransactionRequest.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
    [singlePartnerTransaction.rejected]: (state, action) => {
      state.hasError = action.payload;
    },
  },
});
export const {
  getPartnersList,
  setsinglePartner,
  setErrorEmpty,
  setUsernameStatus,
  setPendingTransactions,
  setAllTransactions,
  setSinglePartnerTransaction,
  setRejectedTransactions,
} = partnerSlice.actions;

export default partnerSlice.reducer;
