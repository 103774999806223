import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import validationSchemas from "../../utils/validations/validationSchemas";
import CustomBtn from "../../components/CustomBtn";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineCheckCircle } from "react-icons/ai";
import MessageModal from "../../shared/loader/MessageModal";
import { setMessage } from "../../features/appSlice";
import CustomInput from "../../components/CustomInput";
import {
  addNewPartnerRequest,
  singlePartnerRequest,
  updatePartnerRequest,
  checkUsernameRequest,
} from "../../features/partnerSlice";

const AddNewPartner = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const message = useSelector((state) => state.app.message);
  const loading = useSelector((state) => state.app.isLoading);
  const submitError = useSelector((state) => state.partners.hasError);
  const usernameAvailablity = useSelector(
    (state) => state.partners.usernameStatus
  );
  const singlePartner = useSelector((state) => state.partners.singlePartner);

  const clearNotificationMessage = () => {
    dispatch(setMessage(""));
    navigate("/partners-menu");
  };

  const checkUsername = (params) => {
    if (!params) return;
    dispatch(checkUsernameRequest(params));
  };

  const onCreate = (values) => {
    if (props.edit) {
      let dataWithId = { ...values, id: singlePartner._id };
      dispatch(updatePartnerRequest(dataWithId));
    } else {
      dispatch(addNewPartnerRequest(values));
    }
  };

  useEffect(() => {
    if (props.edit) {
      dispatch(singlePartnerRequest(id));
    }
  }, [dispatch, props.edit, id]);

  React.useEffect(() => {
    if (message) {
      setTimeout(() => {
        clearNotificationMessage();
      }, 4000);
    }
    // eslint-disable-next-line
  }, [message]);

  return (
    <>
      {!message ? (
        <div
          style={{ minHeight: "80vh" }}
          className="mt-4   bg-secoundry shadow-dhruv rounded dark:bg-darkmodeSecoundry "
        >
          <div className=" pb-8">
            <h2 className=" font-semibold px-4 text-lg ml-4 pt-4 text-blue-500 dark:text-darkmodeFots ">
              {props.edit ? "Update Partner" : "Create Partner"}
            </h2>
            <Formik
              enableReinitialize
              initialValues={{
                fullName: (props.edit && singlePartner.fullName) || "",
                username: (props.edit && singlePartner.username) || "",
                email: (props.edit && singlePartner.email) || "",
                password: (props.edit && singlePartner.password) || "",
              }}
              validationSchema={validationSchemas.registerPartnerValidation}
              onSubmit={onCreate}
            >
              {({ errors, touched, values }) => (
                <Form autoComplete="off">
                  <div
                    className={`flex justify-start md:flex-row flex-col  items-center pt-4 `}
                  >
                    <div className=" md:w-2/3 w-full  md:px-4">
                      <div className={`px-4 w-full`}>
                        <CustomInput
                          errors={errors.fullName}
                          touched={touched.fullName}
                          title="Enter Full Name"
                          name="fullName"
                          placeholder="Enter Name"
                        />
                      </div>
                    </div>
                    <div className="md:w-2/3 w-full px-2 md:px-0 ">
                      <div
                        className={`px-2  pr-8 w-full flex justify-between items-center`}
                      >
                        <CustomInput
                          errors={errors.username}
                          touched={touched.username}
                          title="Unique Username"
                          name="username"
                          placeholder="Enter username"
                        />
                        <button
                          type="button"
                          onClick={() => checkUsername(values.username)}
                          className={` hover:bg-opacity-80 w-full  md:w-auto text-sm disabled:bg-gray-400 disabled:text-gray-100 py-2 px-4 rounded text-white mt-1 lg:ml-5 outline-none lg:hover:scale-105 transform ${
                            usernameAvailablity
                              ? "bg-green-500"
                              : "bg-indigo-500"
                          }`}
                        >
                          {usernameAvailablity ? (
                            <AiOutlineCheckCircle className="text-xl" />
                          ) : (
                            "Check"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`flex justify-start md:flex-row flex-col  items-center pt-4 `}
                  >
                    <div className=" md:w-2/3 w-full  md:px-6">
                      <div className={`px-2  w-full`}>
                        <CustomInput
                          errors={errors.email}
                          touched={touched.email}
                          title="Enter Email"
                          name="email"
                          placeholder="Enter Email"
                        />
                      </div>
                    </div>
                    <div className="md:w-2/3 w-full px-2 md:px-0 ">
                      <div className={`px-2 pr-8 w-full`}>
                        <CustomInput
                          errors={errors.password}
                          touched={touched.password}
                          title="Enter Password"
                          name="password"
                          placeholder="Enter Password"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-3 py-4">
                    <CustomBtn
                      type="submit"
                      text={props.edit ? "Update Partner" : "Create Partner"}
                      loading={loading}
                    />
                  </div>
                  {submitError && (
                    <div className="p-3 pl-8 text-red-500">{submitError}</div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <MessageModal
          open={message}
          setOpen={clearNotificationMessage}
          message={message}
        />
      )}
    </>
  );
};

export default AddNewPartner;
