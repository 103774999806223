import axios from "axios";

const baseURL = `${process.env.REACT_APP_BASE_URL}/api/admin`;

export const partnersAPI = {
  getAllPartners: async () => {
    return axios
      .get(`${baseURL}/get-all-partner-accounts`)
      .then((res) => res.data);
  },
  getAllPartnerTransactions: async () => {
    return axios
      .get(`${baseURL}/get-all-transactions-partners`)
      .then((res) => res.data);
  },
  getAllPendingPartnerTransactions: async () => {
    return axios
      .get(`${baseURL}/get-all-pending-transactions-partners`)
      .then((res) => res.data);
  },
  getAllRejectedPartnerTransactions: async () => {
    return axios
      .get(`${baseURL}/get-all-rejected-transactions-partners`)
      .then((res) => res.data);
  },
  addNewPartner: async (article) => {
    return await axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/cp/register`, article)
      .then((res) => res.data);
  },
  addProfitPartner: async (all) => {
    const { id, ...rest } = all;
    return await axios
      .post(`${baseURL}/add-profit-to-partner/${id}`, rest)
      .then((res) => res.data);
  },
  getSinglePartner: async (id) => {
    return axios.get(`${baseURL}/single-partner/${id}`).then((res) => res.data);
  },
  singlePartnerTransaction: async (id) => {
    return axios
      .get(`${baseURL}/single-partner-transaction/${id}`)
      .then((res) => res.data);
  },
  approveTransaction: async (all) => {
    const { id, ...rest } = all;
    return axios
      .post(`${baseURL}/approve-partner-withdrawl-transaction/${id}`, rest)
      .then((res) => res.data);
  },

  checkUsername: async (username) => {
    return axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/cp/check-username/${username}`
      )
      .then((res) => res.data);
  },
  updatePartnerDetails: async (details) => {
    const { id, ...rest } = details;
    return await axios
      .put(`${baseURL}/update-partner/${id}`, rest)
      .then((res) => res.data);
  },
  deleteClient: async (id) => {
    return await axios
      .delete(`${baseURL}/delete-manager/${id}`)
      .then((res) => res.data);
  },
};
