import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import validationSchemas from "../../utils/validations/validationSchemas";
import CustomBtn from "../../components/CustomBtn";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MessageModal from "../../shared/loader/MessageModal";
import { setMessage } from "../../features/appSlice";
import NumberInput from "../../components/NumberInput";
import {
  singleAccountInfoRequest,
  changeCustomerParametersRequest,
} from "../../features/customerSlice";
import ToastNotification from "../../shared/ToastNotification";

function isNegative(num) {
  if (num < 0) {
    return true;
  }

  return false;
}

const AdjustParameters = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const message = useSelector((state) => state.app.message);
  const loading = useSelector((state) => state.app.isLoading);
  const submitError = useSelector((state) => state.customers.hasError);
  const singleCustomer = useSelector((state) => state.customers.singleAccount);
  const { equity } = singleCustomer;
  const [errorMessage, setErrorMessage] = useState("");
  const maxDrawDown = equity && Math.floor(-(equity * 5) / 100);

  const clearNotificationMessage = () => {
    dispatch(setMessage(""));
    navigate("/customers-menu/all-accounts");
  };
  const onCreate = (values) => {
    if (equity === 0) {
      return setErrorMessage("Abe pehle Equity to daal le ");
    }
    if (values.usedMargin > singleCustomer.equity) {
      return setErrorMessage("Used Margin must be less than Equity");
    }

    if (
      isNegative(values.minimumfloatingProfitLoss) &&
      values.minimumfloatingProfitLoss < maxDrawDown
    ) {
      return setErrorMessage(
        `Minimum Floating must be less than ${maxDrawDown}`
      );
    }

    if (
      isNegative(values.maximumfloatingProfitLoss) &&
      values.maximumfloatingProfitLoss < maxDrawDown
    ) {
      return setErrorMessage(
        `Maximum Floating must be less than ${maxDrawDown}`
      );
    }

    let dataWithId = { ...values, id };
    dispatch(changeCustomerParametersRequest(dataWithId));
  };

  useEffect(() => {
    dispatch(singleAccountInfoRequest(id));
  }, [dispatch, id]);

  React.useEffect(() => {
    if (message) {
      setTimeout(() => {
        clearNotificationMessage();
      }, 4000);
    }
    // eslint-disable-next-line
  }, [message]);

  return (
    <>
      {!message ? (
        <div
          style={{ minHeight: "80vh" }}
          className="mt-4   bg-secoundry shadow-dhruv rounded dark:bg-darkmodeSecoundry "
        >
          <ToastNotification
            message={errorMessage}
            open={errorMessage}
            setOpen={() => setErrorMessage("")}
          />
          <div className=" pb-8">
            <h2 className=" font-semibold px-4 text-lg ml-4 pt-4 text-blue-500 dark:text-darkmodeFots ">
              Adjust Customer Profit Parameters
            </h2>
            <Formik
              enableReinitialize
              initialValues={{
                usedMargin: singleCustomer.usedMargin || 0,
                minimumfloatingProfitLoss:
                  singleCustomer.minimumfloatingProfitLoss || 0,
                maximumfloatingProfitLoss:
                  singleCustomer.maximumfloatingProfitLoss || 0,
              }}
              validationSchema={validationSchemas.parameterValidation}
              onSubmit={onCreate}
            >
              {({ errors, touched }) => (
                <Form autoComplete="off">
                  <div
                    className={`flex justify-start md:flex-row flex-col  items-center pt-4 `}
                  >
                    <div className=" md:w-1/3 w-full  md:px-4">
                      <div className={`px-4 w-full`}>
                        <NumberInput
                          errors={errors.usedMargin}
                          touched={touched.usedMargin}
                          title="Enter Used Margin"
                          name="usedMargin"
                          placeholder="Used Margin"
                        />
                      </div>
                    </div>
                    <div className=" md:w-1/3 w-full  md:px-4">
                      <div className={`px-4 w-full`}>
                        <NumberInput
                          errors={errors.minimumfloatingProfitLoss}
                          touched={touched.minimumfloatingProfitLoss}
                          title="Enter Minimum floating Profit Loss"
                          name="minimumfloatingProfitLoss"
                          placeholder="Enter Minimum floating Profit Loss"
                        />
                      </div>
                    </div>
                    <div className=" md:w-1/3 w-full  md:px-4">
                      <div className={`px-4 w-full`}>
                        <NumberInput
                          errors={errors.maximumfloatingProfitLoss}
                          touched={touched.maximumfloatingProfitLoss}
                          title="Enter Maximum floating Profit Loss"
                          name="maximumfloatingProfitLoss"
                          placeholder="Enter Maximum floating Profit Loss"
                        />
                      </div>
                    </div>
                  </div>
                  {singleCustomer && (
                    <p className=" font-semibold px-4 text-xs ml-4 pt-4 text-red-500 dark:text-darkmodeFots ">
                      {`Username ${singleCustomer.username} has ${singleCustomer.equity} $ equity`}
                    </p>
                  )}
                  <div className="px-3 py-4">
                    <CustomBtn
                      type="submit"
                      text={"Add Profit to Customer"}
                      loading={loading}
                    />
                  </div>
                  {submitError && (
                    <div className="p-3 pl-8 text-red-500">{submitError}</div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <MessageModal
          open={message}
          setOpen={clearNotificationMessage}
          message={message}
        />
      )}
    </>
  );
};

export default AdjustParameters;
