const allTableHeaders = {
  partnerTableHeader: [
    {
      name: "Name",
      code: "fullName",
      type: "string",
      formatFunction: "smallString",
      stringClip: "25",
      textHighlight: true,
    },
    {
      name: "User Name",
      code: "username",
      type: "string",
      formatFunction: "smallString",
      stringClip: "15",
    },
    {
      name: "Status",
      code: "status",
      type: "string",
    },
    {
      name: "Balance",
      code: "profit",
      type: "number",
    },
    {
      name: "Email",
      code: "email",
      type: "number",
      formatFunction: "smallString",
      stringClip: "18",
    },
    {
      name: "Joined on",
      code: "createdAt",
      type: "date",
      formatFunction: "formatDate",
    },
  ],
  transactionTableHeader: [
    {
      name: "User Name",
      code: "username",
      type: "string",
      formatFunction: "smallString",
      stringClip: "25",
      textHighlight: true,
    },
    {
      name: "Type",
      code: "type",
      type: "string",
    },
    {
      name: "Amount",
      code: "amount",
      type: "number",
    },
    {
      name: "Status",
      code: "status",
      type: "string",
    },

    {
      name: "Date",
      code: "createdAt",
      type: "date",
      formatFunction: "formatDate",
    },
  ],
  customerTableHeader: [
    {
      name: "Name",
      code: "fullName",
      type: "string",
      formatFunction: "smallString",
      stringClip: "25",
      textHighlight: true,
    },
    {
      name: "User Name",
      code: "username",
      type: "string",
      formatFunction: "smallString",
      stringClip: "15",
    },
    {
      name: "Trades ON ?",
      code: "closeAllTrades",
      type: "string",
    },
    {
      name: "Equity",
      code: "equity",
      type: "number",
    },
    {
      name: "Profit",
      code: "profit",
      type: "number",
    },
    {
      name: "Joined on",
      code: "createdAt",
      type: "date",
      formatFunction: "formatDate",
    },
  ],
  customerAccountTableHeader: [
    {
      name: "User Name",
      code: "username",
      type: "string",
      formatFunction: "smallString",
      stringClip: "15",
    },
    {
      name: "Equity",
      code: "equity",
      type: "number",
    },
    {
      name: "Profit",
      code: "profit",
      type: "number",
    },
    {
      name: "Used Margin",
      code: "usedMargin",
      type: "number",
    },
    {
      name: "Min Profit",
      code: "minimumfloatingProfitLoss",
      type: "number",
    },
    {
      name: "Max Profit",
      code: "maximumfloatingProfitLoss",
      type: "number",
    },
  ],
};

export default allTableHeaders;
