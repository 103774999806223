import axios from "axios";

const baseURL = `${process.env.REACT_APP_BASE_URL}/api/admin`;

export const customersAPI = {
  getAllCustomers: async () => {
    return axios
      .get(`${baseURL}/get-all-customer-accounts`)
      .then((res) => res.data);
  },
  getAllCustomerTransactions: async () => {
    return axios
      .get(`${baseURL}/get-all-transactions-customers`)
      .then((res) => res.data);
  },
  getAllPendingCustomerTransactions: async () => {
    return axios
      .get(`${baseURL}/get-all-pending-transactions-customers`)
      .then((res) => res.data);
  },
  getAllRejectedCustomerTransactions: async () => {
    return axios
      .get(`${baseURL}/get-all-rejected-transactions-customers`)
      .then((res) => res.data);
  },
  addNewCustomer: async (article) => {
    return await axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/user/register`, article)
      .then((res) => res.data);
  },
  addProfitCustomer: async (all) => {
    const { id, ...rest } = all;
    return await axios
      .post(`${baseURL}/add-profit-to-customer/${id}`, rest)
      .then((res) => res.data);
  },
  getSingleCustomer: async (id) => {
    return axios
      .get(`${baseURL}/single-customer/${id}`)
      .then((res) => res.data);
  },
  singleCustomerWithAccount: async (id) => {
    return axios
      .get(`${baseURL}/single-customer-with-account-detail/${id}`)
      .then((res) => res.data);
  },
  getAllCustomerAccountsWithParameters: async () => {
    return axios
      .get(`${baseURL}/get-all-customer-accounts-with-parameters`)
      .then((res) => res.data);
  },
  singleCustomerTransaction: async (id) => {
    return axios
      .get(`${baseURL}/single-customer-transaction/${id}`)
      .then((res) => res.data);
  },
  singleCustomerAccount: async (id) => {
    return axios
      .get(`${baseURL}/get-customer-account-detail/${id}`)
      .then((res) => res.data);
  },
  approveWithdrawlTransaction: async (all) => {
    const { id, ...rest } = all;
    return axios
      .post(`${baseURL}/approve-withdrawl-transaction-customer/${id}`, rest)
      .then((res) => res.data);
  },

  equityDeposit: async (all) => {
    const { id, ...rest } = all;
    return axios
      .post(`${baseURL}/deposit-transaction-customer/${id}`, rest)
      .then((res) => res.data);
  },

  approveDepositTransaction: async (all) => {
    const { id, ...rest } = all;
    return axios
      .post(`${baseURL}/approve-deposit-transaction-customer/${id}`, rest)
      .then((res) => res.data);
  },
  checkUsername: async (username) => {
    return axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/user/check-username/${username}`
      )
      .then((res) => res.data);
  },
  updateCustomerDetails: async (details) => {
    const { id, ...rest } = details;
    return await axios
      .put(`${baseURL}/update-customer/${id}`, rest)
      .then((res) => res.data);
  },
  updateParameters: async (details) => {
    const { id, ...rest } = details;
    return await axios
      .put(`${baseURL}/change-parameters-customer/${id}`, rest)
      .then((res) => res.data);
  },
};
