import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  approveWithdrawlTransactionRequest,
  singleCustomerTransaction,
} from "../../features/customerSlice";
import ApproveModal from "../../components/modals/ApproveModal";

const ApproveTransaction = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const deletemessage = useSelector((state) => state.app.message);
  const singleTransaction = useSelector(
    (state) => state.customers.singleCustomerTransaction
  );

  const deleteF = () => {
    let allData = {
      status: props.reject ? "rejected" : "confirmed",
      id,
    };
    if (singleTransaction.type === "withdraw") {
      dispatch(approveWithdrawlTransactionRequest(allData));
    }
    if (singleTransaction.type === "deposit") {
      dispatch(approveWithdrawlTransactionRequest(allData));
    }
  };

  useEffect(() => {
    dispatch(singleCustomerTransaction(id));
  }, [dispatch, id]);

  return (
    <ApproveModal
      message={deletemessage}
      cancelLink="/customers-menu/pending-transactions"
      deleteFunction={deleteF}
      amount={singleTransaction.amount}
      client={singleTransaction.username}
      reject={props.reject}
      userType="Customer"
      transactionType={singleTransaction.type}
    />
  );
};

export default ApproveTransaction;
