import { BsCardChecklist, BsPatchPlus } from "react-icons/bs";
import { BiTransfer } from "react-icons/bi";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { HiOutlineViewGridAdd } from "react-icons/hi";

export const menuWithIcon = {
  partnerMenu: [
    {
      name: "All Partners",
      Icon: BsCardChecklist,
      link: "/partners-menu/list-partners",
    },
    {
      name: "Transactions",
      Icon: BiTransfer,
      link: "/partners-menu/all-transactions",
    },
    {
      name: "Pending Transactions",
      Icon: BiTransfer,
      link: "/partners-menu/pending-transactions",
    },
    {
      name: "Add Partner",
      Icon: BsPatchPlus,
      link: "/partners-menu/add-new-partner",
    },
    {
      name: "Rejected Transactions",
      Icon: HiOutlineViewGridAdd,
      link: "/partners-menu/rejected-transactions",
    },
  ],
  customerMenu: [
    {
      name: "All Customers",
      Icon: BsCardChecklist,
      link: "/customers-menu/list-customers",
    },
    {
      name: "Transactions",
      Icon: BiTransfer,
      link: "/customers-menu/all-transactions",
    },
    {
      name: "Pending Transactions",
      Icon: BiTransfer,
      link: "/customers-menu/pending-transactions",
    },
    {
      name: "Add Customer",
      Icon: BsPatchPlus,
      link: "/customers-menu/add-new-customer",
    },
    {
      name: "Rejected Transactions",
      Icon: HiOutlineViewGridAdd,
      link: "/customers-menu/rejected-transactions",
    },
    {
      name: "Handle Parameters",
      Icon: FaMoneyCheckAlt,
      link: "/customers-menu/all-accounts",
    },
  ],
};
