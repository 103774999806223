import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { BsPerson } from "react-icons/bs";
import { CgDollar } from "react-icons/cg";
import { FaUserNinja } from "react-icons/fa";
import {
  getAllPartnersRequest,
  getAllTransactionsRequest,
} from "../../features/partnerSlice";
import {
  getAllRejectedTransactionsRequest,
  getAllPendingTransactionsRequest,
} from "../../features/customerSlice";

const TopCompo = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userDetails);
  const customerPendingTransactions = useSelector(
    (state) => state.customers.pendingTransactions
  );
  const customerRejectedTransactions = useSelector(
    (state) => state.customers.rejectedTransactions
  );
  const customersArray = useSelector((state) => state.customers.customersList);
  const partnersArray = useSelector((state) => state.partners.partnersList);

  useEffect(() => {
    dispatch(getAllPartnersRequest());
    dispatch(getAllPendingTransactionsRequest());
    dispatch(getAllTransactionsRequest());
    dispatch(getAllRejectedTransactionsRequest());
  }, [dispatch]);

  return (
    <div className="grid lg:grid-cols-3 grid-cols-1 lg:grid-flow-col grid-flow-row lg:gap-3 pb-4  items-center w-full mt-4">
      <div className="bg-secoundry dark:bg-darkmodeSecoundry shadow-dhruv pl-4 py-[18px] w-full ">
        <h3 className=" text-iconColor text-sm dark:text-darkmodeFots">
          Congratulations 🎉{" "}
          {user !== undefined && user.name && (
            <span className="text-indigo-600">{user.name}!</span>
          )}
        </h3>
        <h4 className=" text-iconColor text-xs py-3 dark:text-darkmodeFots">
          Pending Customer Transactions
        </h4>
        <Link to="/customers-menu/pending-transactions">
          <p className=" font-medium text-botton text-xl hover:underline">
            {customerPendingTransactions
              ? customerPendingTransactions.length
              : 0}
          </p>
        </Link>
      </div>
      <div className="bg-secoundry dark:bg-darkmodeSecoundry shadow-dhruv w-full col-span-2 p-2 py-5 px-3">
        <h3 className=" text-iconColor text-sm dark:text-darkmodeFots">
          Statistics
        </h3>
        <div className="mt-2 grid lg:grid-cols-3 grid-rows-3 lg:grid-rows-1  gap-2 w-full">
          <div className="  flex items-center">
            <div className=" bg-botton/80 rounded-full p-4">
              <FaUserNinja className=" text-secoundry dark:text-darkmodeFots text-xl" />
            </div>
            <div className=" font-semibold text-iconColor text-sm ml-4">
              <h3 className=" font-bold text-xl text-red-600 dark:text-darkmodeFots">
                {customersArray ? customersArray.length : 0}
              </h3>
              <Link to="/customers-menu/list-customers">
                <h6 className="dark:text-darkmodeFots text-xs hover:underline hover:text-blue-600">
                  Total Customers
                </h6>
              </Link>
            </div>
          </div>
          <div className=" flex items-center">
            <div className="bg-botton/80 rounded-full p-4">
              <BsPerson className=" text-secoundry text-2xl dark:text-darkmodeFots" />
            </div>
            <div className=" font-semibold text-iconColor text-sm ml-6 items-center justify-center">
              <h3 className=" font-bold text-xl text-red-600 dark:text-darkmodeFots ">
                {partnersArray ? partnersArray.length : 0}
              </h3>
              <Link to="/partners-menu/list-partners">
                <h6 className="dark:text-darkmodeFots text-xs hover:underline hover:text-blue-600">
                  Total Partners
                </h6>
              </Link>
            </div>
          </div>

          <div className=" flex items-center ">
            <div className=" bg-botton/80 rounded-full p-4">
              <CgDollar className=" text-secoundry text-2xl dark:text-darkmodeFots" />
            </div>
            <div className=" font-semibold text-iconColor text-sm ml-6 items-center justify-center">
              <h3 className=" font-bold text-xl text-red-600 dark:text-darkmodeFots">
                {customerRejectedTransactions
                  ? customerRejectedTransactions.length
                  : 0}
              </h3>
              <Link to="/customers-menu/rejected-transactions">
                <h6 className="dark:text-darkmodeFots text-xs hover:text-blue-600 hover:underline">
                  Rejected Transactions
                </h6>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopCompo;
