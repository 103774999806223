import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import validationSchemas from "../../utils/validations/validationSchemas";
import CustomBtn from "../../components/CustomBtn";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MessageModal from "../../shared/loader/MessageModal";
import { setMessage } from "../../features/appSlice";
import NumberInput from "../../components/NumberInput";
import {
  singlePartnerRequest,
  addProfitToPartnerRequest,
} from "../../features/partnerSlice";

const AddProfitToPartner = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const message = useSelector((state) => state.app.message);
  const loading = useSelector((state) => state.app.isLoading);
  const submitError = useSelector((state) => state.partners.hasError);
  const singlePartner = useSelector((state) => state.partners.singlePartner);

  const clearNotificationMessage = () => {
    dispatch(setMessage(""));
    navigate("/partners-menu");
  };

  const onCreate = (values) => {
    let dataWithId = { ...values, id };
    dispatch(addProfitToPartnerRequest(dataWithId));
  };

  useEffect(() => {
    dispatch(singlePartnerRequest(id));
  }, [dispatch, id]);

  React.useEffect(() => {
    if (message) {
      setTimeout(() => {
        clearNotificationMessage();
      }, 4000);
    }
    // eslint-disable-next-line
  }, [message]);

  return (
    <>
      {!message ? (
        <div
          style={{ minHeight: "80vh" }}
          className="mt-4   bg-secoundry shadow-dhruv rounded dark:bg-darkmodeSecoundry "
        >
          <div className=" pb-8">
            <h2 className=" font-semibold px-4 text-lg ml-4 pt-4 text-blue-500 dark:text-darkmodeFots ">
              Add Profit to Partner
            </h2>
            <Formik
              enableReinitialize
              initialValues={{
                profit: 0,
              }}
              validationSchema={validationSchemas.addProfitIsNumberValidation}
              onSubmit={onCreate}
            >
              {({ errors, touched }) => (
                <Form autoComplete="off">
                  <div
                    className={`flex justify-start md:flex-row flex-col  items-center pt-4 `}
                  >
                    <div className=" md:w-1/3 w-full  md:px-4">
                      <div className={`px-4 w-full`}>
                        <NumberInput
                          errors={errors.profit}
                          touched={touched.profit}
                          title="Enter Amount"
                          name="profit"
                          placeholder="Enter Name"
                        />
                      </div>
                    </div>
                  </div>
                  {singlePartner && (
                    <h6 className="pl-8 text-xs lg:text-base text-red-600 leading-relaxed">
                      Are You Sure you want to add Profit in{"  "}
                      <span className="text-blue-500 capitalize">
                        {singlePartner.fullName}'s{"  "}
                      </span>
                      Account ?
                      <br />
                      This can not be undone
                      <br />
                      Think twice before continue
                    </h6>
                  )}
                  <div className="px-3 py-4">
                    <CustomBtn
                      type="submit"
                      text={"Add Profit to Partner"}
                      loading={loading}
                    />
                  </div>
                  {submitError && (
                    <div className="p-3 pl-8 text-red-500">{submitError}</div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <MessageModal
          open={message}
          setOpen={clearNotificationMessage}
          message={message}
        />
      )}
    </>
  );
};

export default AddProfitToPartner;
